import React from "react";
import { graphql, Link } from "gatsby";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import HeroSection from "../components/herosection";
import aboutuswhowearehero from "../images/about-us-who-we-are-hero.jpg";
import aboutusourcorevalueshero from "../images/about-us-our-core-values-hero.jpg";
import SingleButton from "../components/singlebutton";
import Arrow from "../components/arrow";

import passion from "../images/passion.png";
import continuousLearning from "../images/continuous-learning.png";
import accountability from "../images/accountability.png";
import passionauthenticity from "../images/authenticity.png";
import allForOneAndOneForAll from "../images/all-for-one-and-one-for-all.png";
import icon from "../images/about-icon.png";

import awardslogo0 from "../images/awards/awards-logo0.png";
import awardslogo1 from "../images/awards/awards-logo1.jpg";
import awardslogo2 from "../images/awards/awards-logo2.jpg";
import awardslogo3 from "../images/awards/awards-logo3.jpg";
import awardslogo4 from "../images/awards/awards-logo4.jpg";
import awardslogo5 from "../images/awards/awards-logo5.jpg";
import awardslogo6 from "../images/awards/awards-logo6.jpg";
import awardslogo7 from "../images/awards/awards-logo7.jpg";
import awardslogo8 from "../images/awards/awards-logo8.jpg";
import awardslogo9 from "../images/awards/awards-logo9.jpg";

import "../scss/about.scss";

const AboutPage = ({ data }) => {
  const metadescription = "Next&Co are an independent digital and traditional performance marketing agency, visit our site or call 1300 191 950.";
  const metatitle = "About Us - Next&Co";
  const metaurl = typeof window !== 'undefined' ? window.location.href : '';

  return (
    <>
	<Helmet>
            <title>{metatitle}</title>
            <meta name="description" content={metadescription}/>
            <meta name="title" content={metatitle} />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={metaurl} />
            <meta property="og:title" content={metatitle} />
            <meta property="og:description" content={metadescription} />
            <meta property="og:image" content="facebook.png" />
            <meta property="twitter:url" content={metaurl} />
            <meta property="twitter:title" content={metatitle} />
            <meta property="twitter:description" content={metadescription} />
            <meta property="twitter:image" content="twitter.png" />
	</Helmet>
	<Arrow/>
	<div className="about-us-page">
            <Layout>
		<HeroSection
		  heading="About us"
		icon={icon}
		  image={data.file.childImageSharp.fluid}
		/>
		<section id="row-one">
		    <div className="outer-container">
			<div className="container-max-width">
			    <div className="inner-container">
				<div className="col-one">
				    <h2 className="col-one-heading">Why we exist</h2>
				</div>
				<div className="col-two">
				    <p>
					Founded in 2010, we exist to make the digital world a better place. Our goal
					is to work with our clients to define what ‘better’ looks
					like and how it can be measured in a digital environment.
				    </p>
				    <p>
					More sales, enquiries, downloads, or any other form of
					engagement – we take accountability for measuring the
					outcomes of our work and the pursuit of continuous
					improvement.
				    </p>
				</div>
			    </div>
			</div>
		    </div>
		</section>
		<section id="row-two"
			 style={{
			   backgroundImage: `url(${aboutuswhowearehero})`,
			   backgroundPosition: `center center`,
			   backgroundRepeat: `no-repeat`,
			   backgroundSize: `cover`,
			 }}
		>
		    <div className="outer-container">
			<div className="container-max-width">
			    <div className="inner-container">
				<div className="col-one">
				    <h2 className="col-one-heading">Who we are</h2>
				    <p>
					We are strategists, media buyers, creatives and techies who
					love what we do. We share a deep love of the digital world
					that has profoundly influenced our lives and care deeply
					about the impact our work has within it.
				    </p>
				</div>
			    </div>
			</div>
		    </div>
		</section>
		<section id="row-three">
		    <div className="outer-container">
			<div className="container-max-width">
			    <div className="inner-container row">
				<div className="col-one">
				    <h2 className="col-one-heading">Our core values</h2>
				</div>
				<div className="col-two">
				    {/* <img src={aboutusourcorevalueshero} alt="" /> */}
				    <div className="list-items">
					<div className="list-item">
					    <span className="icon">
						<img alt="" src={passion}/>
					    </span>
					    <div className="content">
						<h3>Passion</h3>
						<p>The fuel for everything we do</p>
					    </div>
					</div>
					<div className="list-item">
					    <span className="icon">
						<img alt="" src={continuousLearning} style={{maxWidth: `50px`}}/>
					    </span>
					    <div className="content">
						<h3>Continuous Learning</h3>
						<p>Be humble and always aim to know more tomorrow</p>
					    </div>
					</div>
					<div className="list-item">
					    <span className="icon">
						<img alt="" src={accountability} style={{maxWidth: `45px`}}/>
					    </span>
					    <div className="content">
						<h3>Accountability</h3>
						<p>The buck stops with us</p>
					    </div>
					</div>
					<div className="list-item">
					    <span className="icon">
						<img alt="" src={passionauthenticity}  style={{maxWidth: `45px`}}/>
					    </span>
					    <div className="content">
						<h3>Authenticity</h3>
						<p>Say it like it is.</p>
					    </div>
					</div>
					<div className="list-item">
					    <span className="icon">
						<img alt="" src={allForOneAndOneForAll}  style={{maxWidth: `35px`}}/>
					    </span>
					    <div className="content">
						<h3>All for 1 and 1 for all</h3>
						<p>Support and help everyone in our network</p>
					    </div>
					</div>
				    </div>
				</div>
			    </div>
			</div>
		    </div>
	    </section>
	    <section id="awards-section">
		<div className="outer-container">
		    <div className="container-max-width">
			<div className="inner-container">
			    <div class="small-inner-container">
				<h2>Our Awards</h2>
				<div class="row">
				    <div class="image-container">
					<img src={awardslogo0} alt="" />
				    </div>
				    <div class="image-container">
					<img src={awardslogo1} alt="" />
				    </div>
				    <div class="image-container">
					<img src={awardslogo2} alt="" />
				    </div>
				    <div class="image-container">
					<img src={awardslogo3} alt="" />
				    </div>
				    <div class="image-container">
					<img src={awardslogo4} alt="" />
				    </div>
				    <div class="image-container">
					<img src={awardslogo5} alt="" />
				    </div>
				    <div class="image-container">
					<img src={awardslogo6} alt="" />
				    </div>
				    <div class="image-container">
					<img src={awardslogo7} alt="" />
				    </div>
				    <div class="image-container">
					<img src={awardslogo8} alt="" />
				    </div>
				    <div class="image-container">
					<img src={awardslogo9} alt="" />
				    </div>
				</div>
			    </div>
			</div>
		    </div>
		</div>
	    </section>
	    <section id="row-four">
		<div className="outer-container">
			<div className="container-max-width">
			    <div className="inner-container">
				<div className="col-one">
				    <h3>
					Many of Australia's leading businesses partner with us, you
					should too.
				    </h3>
				    <SingleButton buttonText="Get in touch"/>
				</div>
			    </div>
			</div>
		    </div>
		</section>
            </Layout>
	</div>
    </>
  );
};

export default AboutPage;

export const query = graphql`
  query {
    file(relativePath: { eq: "about-herosection-image.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 2880, quality: 100) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
  }
`
